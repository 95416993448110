body, html {
  /*height: 100%;*/ 
  margin: 0;
}

      .header {
          /*border: 5px solid green;*/
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;


      }


      .container-header-buttons {
        display: flex;
        justify-content: center;
    }


    .input-field {

      width: 30%;
  }

  p.statistics {
    padding-top:  18px;
  }


  @media screen and (max-width: 800px) {

  .statistics {
    padding-left:  18px;
  }



  }


  .logo > img {
    width:600px; /* you can use % */
    height: auto;
    padding-bottom: 30px;

  }

      @media screen and (max-width: 600px) {
        .header {
          justify-content: center;
         }

         .input-field {

          width: 90%;
      }

 
      .logo > img {
        width:400px; /* you can use % */
        height: auto;
        padding-bottom: 30px;
        padding-top: 60px;

      }


      }
      



      .mon-button {
          padding: 20px 10px;
      }

      .logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          padding-top: 10%;
          /*height: 100vh;*/

      }

      .logo > p {
          text-align: center;
      }






      .listElements {
        padding-top: 0px;
        /*border: 5px solid green;*/
      }

      p {
        font-size: 18px;
        text-align:justify;
      }

      .resultsInfo {
          font-size: 18px;
        margin-right:auto;
        padding-left: 10px;;
      }


      .toz {
        border: 5px solid red;
        display: block;
      }


      .warning {
        text-align:left;
        margin-top: 20px;

      }

      .keyWords {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content:  flex-start;
      align-items: center;

      


      }